import '../assets/styles/profile.scss';
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import fotoprofilo from '../assets/images/fotoprofilo.jpg';
import tel from '../assets/icon/tel.svg';
import email from '../assets/icon/email.svg';
import PdfModal from './pdfModal';

function Profile() {

    const [showModal, setShowModal] = useState(false);

    return(
        <>
            <div className="profile-container">
                <Container>
                    <Row>
                        <Col className="profile-box-container">
                            <div>
                                <Container>
                                    <Row>
                                        <Col sm={4}></Col>
                                        <Col sm={8}>
                                            <h1>Francesco Frazzetto</h1>
                                            <div className="profile-info-container">
                                                <img src={email} alt="email"/>
                                                <p>Frax095@gmail.com</p>
                                            </div>
                                            <div className="profile-info-container">
                                                <img src={tel} alt="tel"/>
                                                <p>3345613834</p>
                                            </div>
                                            <div className="profile-info-container" onClick={() => setShowModal(true)} >
                                                <div>
                                                    <h5>Curriculum Vitae</h5>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <img src={fotoprofilo} alt="fotoprofilo"/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <PdfModal showModal={showModal} closeModal={() => setShowModal(false)}/>
        </>
    )
}

export default Profile;