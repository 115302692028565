import '../assets/styles/pdfModal.scss';
import React from 'react';
import curriculumVitae from '../assets/pdfCurriculum/curriculum.pdf';
import { Container, Row } from 'react-bootstrap';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfModal = (props) => {

    return (
        <div className={props.showModal ? "pdf-modal-container" : "hidePdfModal"}>
            <Container>
                <Row className="justify-content-center">
                    <Document file={curriculumVitae}>
                        <Page className="pdfSize" pageNumber={1}/>
                    </Document>
                </Row>
                <Row className="justify-content-center">
                    <div onClick={props.closeModal} className="close-modal-curriculum-button">X</div>
                    <a className="close-modal-curriculum-button" href={curriculumVitae} download="curriculum-vitae">Download PDF</a>
                </Row>
            </Container>
        </div>
    )
}

export default PdfModal;