import '../assets/styles/navbar.scss';
import React from 'react';
import ff from '../assets/icon/ff.svg';
import linkedin from '../assets/icon/in.svg';
import git from '../assets/icon/git.svg';
import skills from '../assets/icon/skills.svg';
import works from '../assets/icon/works.svg';
import me from '../assets/icon/me.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

function Navbar() {
    return (
        <div className="navbar-container">
            <div className="navbar-logo-container">
                <Link to="/"><img src={ff} alt="ff"/></Link>
            </div>
            <div className="navbar-tab">
                <Link to="/skills" className="tab-container">
                    <img src={skills} alt="skills"/>
                    <p>SKILLS</p>
                </Link>
                <Link to="/works" className="tab-container">
                    <img src={works} alt="works"/>
                    <p>WORKS</p>
                </Link>
                <Link to="/profile" className="tab-container">
                    <img src={me} alt="me"/>
                    <p>PROFILE</p>
                </Link>
                {/* <Link to="/profile" className="tab-container">
                    <img src={me} alt="me"/>
                    <p>CONTACTS</p>
                </Link> */}
            </div>
            <div className="social-container">
                <a href="https://www.linkedin.com/in/francescofrazzetto/" target="_blank">
                    <img src={linkedin} alt="linkedin"/>
                </a>
                <a href="https://github.com/Frax095" target="_blank">
                    <img src={git} alt="git"/>
                </a>
            </div>
        </div>
    )
}

export default Navbar;