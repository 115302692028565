import '../assets/styles/gravity.scss';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Tween, Timeline, SplitLetters } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';

function Gravity() {
    return(
        <Controller>
            <Scene
                reverse={true} 
                duration={400}
                triggerHook="onLeave"
            >
                <Tween 
                    from= {{
                        opacity: 1
                    }}

                    to= {{
                        opacity: 0
                    }}
                >                        
                    <Container>
                        <Row>
                            <Col md={6} className="gravity-card-container">
                                <a href="http://francescofrazzetto.altervista.org/work/gravity" className="gravity-card" target="_blank">
                                    <h1>Gravity</h1>
                                    <p>Powered by React</p>
                                    <div></div>
                                </a>
                            </Col>
                            <Col md={6} className="gravity-text-container">
                                <div className="gravity-text">
                                    <p>
                                        Un esempio di responsive web page
                                        dallo stile fresco e colorato
                                        <br/><br/>
                                        Sono state utilizzate le librerie
                                        SlickSlider e Bootstrap
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Tween>
            </Scene>
        </Controller>
    )
}

export default Gravity;