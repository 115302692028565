import '../assets/styles/skill.scss';
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import macbook from '../assets/images/macbook.png';
import js from '../assets/skills/js.svg';
import html from '../assets/skills/html.svg';
import css from '../assets/skills/css.svg';
import react from '../assets/skills/react.svg';
import angular from '../assets/skills/angular.svg';
import uiux from '../assets/skills/uiux.svg';
import wordpress from '../assets/skills/wordpress.svg';
import photoshop from '../assets/skills/photoshop.svg';
import illustrator from '../assets/skills/illustrator.svg';
import bootstrap from '../assets/skills/bootstrap.svg';

function Skill() {
    return(
        <div className="skill-container">
            <Container>
                <Row className="col-order">
                    <Col lg={5} className="skill-text-container">
                        <h2>
                            Ciao, il mio nome è Francesco
                        </h2>
                        <p>
                            Faccio sempre il mio lavoro con passione ed 
                            ogni nuovo progetto diventa sempre una sfida per
                            aggiornarmi ed affinare le mie competenze.
                            <br /><br />
                            La mia SUPER SKILL è l'entusiasmo che
                            trasmetto nei miei lavori, ma scrivo
                            anche codice!
                            <br /><br />
                            Il mio ambiente racchiude sia lo sviluppo Front-End
                            che l'interfaccia grafica.
                            <br />
                            Lavorare su un progetto significa quindi
                            prendermi cura di ogni aspetto legato
                            a User Experience e User Interface.
                            <br />
                            Mi piace, pertanto, lavorare sui dettagli, sui 
                            particolari che fanno la differenza sia sull'impatto 
                            estetico che funzionale
                            <br /><br />
                            Trovo piacevole, inoltre, e stimolante 
                            lavorare in team e collaborare
                            per raggiungere il giusto equilibrio tra le parti
                            <br /><br />
                            E' per me molto importante realizzare
                            i miei obiettivi professionali e sono 
                            detarminato a raggiungerli 
                            con pazienza ed impegno
                        </p>
                    </Col>
                    <Col lg={7} className="skill-image-container">
                        <div className="macbook-container">
                            <div className="macbook-box">
                                <img className="macbook" src={macbook} alt="macbook"/>
                                <div className="skills">
                                    <img src={js} alt="js"/>
                                    <img src={html} alt="html"/>
                                    <img src={css} alt="css"/>
                                    <img src={bootstrap} alt="bootstrap"/>
                                    <img src={react} alt="react"/>
                                    <img src={angular} alt="angular"/>
                                    <img src={wordpress} alt="wordpress"/>
                                    <img src={uiux} alt="uiux"/>
                                    <img src={photoshop} alt="photoshop"/>
                                    <img src={illustrator} alt="illustrator"/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Skill;