import './App.css';
import React from 'react';
import Navbar from './components/navbar';
import Skill from './components/skill';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from './components/header';
import Works from './components/works';
import Profile from './components/profile';

function App(props) {
  console.log(props)

  return (
    <div className="portfolio-container">
      <Router>
        <Navbar />
        <Route exact path="/" component={Header} />
        <Route exact path="/skills" component={Skill} />
        <Route exact path="/works" component={Works} />
        <Route exact path="/profile" component={Profile} />
      </Router>
    </div>
  );
}

export default App;
