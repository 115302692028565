import '../assets/styles/leperlerare.scss';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Tween, Timeline, SplitLetters } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import leperlerare from '../assets/works/leperlerare.png';
import scroll from '../assets/works/white-scroll.gif';
function LePerleRare() {
    return(
        <Controller>
            <Scene
                reverse={true} 
                duration={400}
                triggerHook="onLeave"
            >
                <Tween 
                    from= {{
                        opacity: 1
                    }}

                    to= {{
                        opacity: 0
                    }}
                >                        
                    <Container>
                        <img className="scroll-image" src={scroll} alt="scroll"/>
                        <Row>
                            <Col md={6} className="lpr-card-container">
                                <img src={leperlerare} alt="leperlerare"/>
                                <a href="https://www.leperlerare.it" className="lpr-card" target="_blank">
                                    <h1>LePerleRare.it</h1>
                                    <p>Powered by Wordpress</p>
                                </a>
                            </Col>
                            <Col md={6} className="lpr-text-container">
                                <div className="lpr-text">
                                    <p>
                                        Un sito pensato ed ideato per rispecchiare
                                        le caratteristiche e la personalità
                                        dell'atelier LePerleRare
                                        <br/><br/>
                                        Tutti i disegni sono stati realizzati a mano
                                        utilizzando photoshop su Ipad
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Tween>
            </Scene>
        </Controller>
    )
}

export default LePerleRare;