import '../assets/styles/works.scss';
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
// import works from '../assets/works/works.svg';
import ReactSnapScroll from 'react-snap-scroll';
import LePerleRare from './leperlerare';
import ReactNews from './react-news';
import Tale from './tale';
import Gravity from './gravity';


function Works() {
    return(
        <>
            <p className="works-logo">works</p>
            <div className="works-container">
                {/* <img className="works-logo" src={works} alt="works"/> */}
                <div className="work-container">
                    <LePerleRare/>
                </div>
                <div className="work-container">
                    <ReactNews/>
                </div>
                <div className="work-container">
                    <Gravity/>
                </div>
                {/* <div className="work-container">
                    <Tale/>
                </div> */}
            </div>
        </>
    )
}

export default Works;