import React from 'react';
import '../assets/styles/logo.scss';

function Logo() {
    return (
        <svg viewBox="0 0 800 600">
            <symbol id="s-text">
                <text 
                    // text-anchor="middle"
                    x="-51%"
                    y="65%"
                    className="text--line"
                    >
                F
                </text>
                <text 
                    // text-anchor="middle"
                    x="52%"
                    y="60%"
                    className="text--line2"
                    >
                F
                </text>              
            </symbol>

            <g>
                <use 
                    href="#s-text"
                    className="text-copy">
                </use>     
                
                <use 
                    href="#s-text"
                    className="text-copy">
                </use>     

                <use 
                    href="#s-text"
                    className="text-copy">
                </use>     

                <use 
                    href="#s-text"
                    className="text-copy">
                </use>     

                <use 
                    href="#s-text"
                    className="text-copy">
                </use>     
            </g>
        </svg>
    )
}

export default Logo;