import '../assets/styles/header.scss';
import React from 'react';
import ff from '../assets/icon/ff.svg';
import Logo from './logo';

function Header() {
    return (
        <div className="header-container">
            <div className="container header">
                <div className="row">
                    <div className="col-md-12 logo-container">
                        <Logo />
                    </div>    
                </div>
                <div className="row">
                    <div className="col-12 header-paragraph">
                        <div className="text-box">
                            <p> Front End Developer & Designer </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;