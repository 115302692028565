import '../assets/styles/react-news.scss';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Tween, Timeline, SplitLetters } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import reactnews from '../assets/works/react-news.png';
import reactNewsVideo from '../assets/video/react-news-video.mp4';
import reactNewsScreen from '../assets/works/react-news-screen.jpg';

function ReactNews2() {
    return(
        <Controller>
            <Scene
                reverse={true} 
                duration={400}
                triggerHook="onLeave"
            >
                <Tween 
                    from= {{
                        opacity: 1
                    }}

                    to= {{
                        opacity: 0
                    }}
                >                        
                    <Container>
                        <Row>
                            <Col className="rn-card-container">
                                <a href="http://francescofrazzetto.altervista.org/work/react-news" className="rn-card" target="_blank">
                                    <img src={reactNewsScreen} alt="reactNewsScreen"/>
                                    <video autoPlay loop>
                                        <source src={reactNewsVideo}/>
                                    </video>
                                </a>
                            </Col>
                            <Col md={6} className="rn-text-container">
                                <div className="rn-text">
                                    <p>
                                        La web app di news dalla grafica
                                        accattivante per leggere le proprie
                                        notizie preferite
                                        <br/><br/>
                                        Ogni dettaglio è stato pensato per un
                                        utilizzo intuitivo e divertente
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Tween>
            </Scene>
        </Controller>
    )
}

export default ReactNews2;